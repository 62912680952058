
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Institute Name",
          key: "ins_name",
          sortable: true,
           width: "220px",
        },
        {
          name: "Course Name",
          key: "course_name",
          sortable: true,
          width: "200px",
        },
        {
          name: "Batch Number",
          key: "batch_number",
          sortable: true,
        },
        {
          name: "Trainee Count",
          key: "trainee_count",
          sortable: true,
        },
        {
          name: "Start Date",
          key: "start_date",
          sortable: true,
           width: "120px",
        },
        {
          name: "End Date",
          key: "end_date",
          sortable: true,
          width: "120px",
        },
        {
          name: "Lead Trainer",
          key: "lead_trainer",
          sortable: true,
          width: "150px",
        },
        {
          name: "Associate Trainer",
          key: "associate_trainer",
          sortable: true,
          width: "170px",
        },
      ],
      tableData: [
        {
          id: 1,
          ins_name:
            "BASIS Institute of Technology and Management - Chittagong ",
          course_name: "Web Application Development - PHP ",
          batch_number: "1 ",
          trainee_count: "30  ",
          start_date: "07-05-2016 ",
          end_date: "25-08-2016",
          lead_trainer: "Mian Zadid Rusdid ",
          associate_trainer: "Md. Yameen Hossain",
        },
        {
          id: 2,
          ins_name:
            "BASIS Institute of Technology and Management - Chittagong ",
          course_name: "Web Application Development - PHP",
          batch_number: "2 ",
          trainee_count: "30 ",
          start_date: "07-05-2016",
          end_date: "25-08-2016",
          lead_trainer: "Mian Zadid Rusdid ",
          associate_trainer: "Md. Yameen Hossain ",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
